export const mapMardkownRemarkToArticle = (article) => ({
  ...article.node.frontmatter,
  link: article.node.fields.slug,
  tags: article.node.fields.tags,
  id: article.node.id,
})

export const menuItems = [
  { label: 'Home', href: 'https://www.auto1-group.com/' },
  {
    label: 'Company',
    href: 'https://www.auto1-group.com/company/',
  },
  {
    label: 'Careers',
    href: 'https://www.auto1-group.com/careers/',
    subMenu: [
      {
        label: 'Overview',
        href: 'https://www.auto1-group.com/careers/',
        isMobile: true,
      },
      { label: 'Teams', href: 'https://www.auto1-group.com/teams/' },
      { label: 'Jobs', href: 'https://www.auto1-group.com/jobs/?country=Germany' },
    ],
  },
  {
    label: 'Press',
    href: 'https://www.auto1-group.com/press/pressrelease/?q=bGFuZ3VhZ2VfcGlja2Vk',
    subMenu: [
      {
        label: 'Overview',
        href: 'https://www.auto1-group.com/press/pressrelease/?q=bGFuZ3VhZ2VfcGlja2Vk',
        isMobile: true,
      },
      {
        label: 'Press Releases',
        href: 'https://www.auto1-group.com/press/pressrelease/',
      },
      {
        label: 'Press Downloads',
        href: 'https://www.auto1-group.com/press/pressdownloads/',
      },
    ],
  },
  { label: 'Blog', href: '/', isRoute: true },
]
/**
 * Truncate string after end of a word
 * @param {string} string - the original string
 * @param {number} length - the truncate bound
 */
export const stringTrunc = (string, length) =>
  string.length > length
    ? ((subString) => subString.substr(0, subString.lastIndexOf(' ')) + '...')(string.substr(0, length - 1))
    : string

export const formatDate = (
  (currentYear) => (dateString) =>
    ((dateObject) =>
      currentYear === dateObject.getYear()
        ? dateObject.toLocaleString('en-us', { month: 'short', day: 'numeric' })
        : dateObject.toLocaleString('en-us', { month: 'short', day: 'numeric' }) + ` ${dateObject.getFullYear()}`)(
      new Date(dateString)
    )
)(new Date().getYear())
