import React from 'react'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'
import ArticlesIndex from './ArticlesIndex'
import { SITE_TITLE } from '../constants'

const TagPage = ({
  data: { allMarkdownRemark: { edges: articles } },
  pageContext: { tag, categories }
}) => (
  <div>
    <Helmet
      title={`#${tag} - Tags - ${SITE_TITLE}`}
      meta={[
        { name: 'description', content: '' },
        { name: 'keywords', content: [tag, ...categories] }
      ]}
    />
    <ArticlesIndex
      categories={categories}
      articles={articles}
      selectedTag={tag}
    />
  </div>
)

export const query = graphql`
  query TagPageQuery($tag: String!) {
    allMarkdownRemark(
      filter: { fields: { tags: { in: [$tag] } } }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      edges {
        node {
          ...pageFields
        }
      }
    }
  }
`

export default TagPage
